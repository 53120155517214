export const USER_DATA = "C2H_UserData";
export const USER_TOKEN = "C2H_UserToken";
export const RESET_TOKEN = "ResetToken";
export const PLAYERS_DETAIL = "PlayersDetail";
export const AUTHENTICATION = "authentication";
export const LOCAL_CART_DATA = "C2H_LocalCartData";
export const RECENT_SEARCHES = "recent_searches";
export const ADD_TO_CARTS_DATA = "C2H_AddToCartsData";
export const RECENT_VIEWED_NEWS = "recent_viewed_news";
export const LANGUAGE = "C2H_Language";

export const get = async (key) => {
  try {
    return await localStorage.getItem(key);
  } catch (e) {}
};

export const save = async (key, value) => {
  try {
    await localStorage.setItem(key, value);
  } catch (e) {}
};

export const remove = async (key) => {
  try {
    await localStorage.removeItem(key);
  } catch (e) {}
};
